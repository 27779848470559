
import { Component, Prop, Vue, Getter, Watch } from "nuxt-property-decorator";
import {} from "vuex-class";
import { BIcon, BIconFilter, BIconBackspace, BIconSearch, BIconTrash2, BIconMap } from "bootstrap-vue";
import { isEmpty } from "@/helpers/isEmpty";

@Component({
  components: {
    BIcon,
    BIconFilter,
    BIconBackspace,
    BIconSearch,
    BIconTrash2,
    BIconMap,
  },
})
export default class SectionSearch extends Vue {
  @Prop() isScrolled!: boolean;
  @Prop() query!: string;

  @Prop() filtersCount: Number;
  @Getter("filter/genre") genre!: string;
  @Getter("layout/offset") offset: number;
  @Getter("layout/header") headerHeight: number;

  public staticCount: number = 0;

  mounted() {
    this.staticCount = Number(this.filtersCount);
  }

  @Watch("$route.query", { immediate: false })
  checkifItworks() {
    this.staticCount = Number(this.filtersCount);
  }

  public disableFilterButton() {
    return false;
  }

  public hideResetButton() {
    if (this.query) {
      return false;
    }

    if (this.staticCount === 0) {
      return true;
    }

    if (this.genre !== "default") {
      return this.filtersCount < 1;
    }

    if (!isEmpty(this.$route.query)) {
      return false;
    }

    if (this.genre === "default") {
      return this.filtersCount === 0;
    }
  }

  public clickOnReset() {
    this.$emit("reset-has-been-clicked");
    this.staticCount = 0;
  }
}
